
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({})
export default class StatsAvatar extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public icon!: string;
  @Prop({ required: true }) public stats!: string;
  @Prop({ required: true }) public color!: string;
}
